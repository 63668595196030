@import "@/assets/scss/main.scss";

#bar {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-items: stretch;
    flex: initial;
    align-self: stretch;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: $elsi-color-bar-bg;
    border-radius: 30px;

    .segment {
        flex: 1;
        height: 20px;
    }

    .segment_1 {
        background-color: $elsi-color-blue;
    }

    .colored:first-child {
    }

    .segment:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }

    // Nach 1 h debuggen die Erkenntnis:
    // .colored:last-child {}
    // … funktioniert prinzipiell nicht.
    .colored:last-of-type {
    }

    .segment:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }

}
