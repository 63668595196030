@import "@/assets/scss/main.scss";

.notVisible {
    opacity: 0.5;
}

.visible {
    visibility: visible;
}


section#questions {
    display: flex;
    flex-direction: column;


    .questions {
        display: grid;
        grid-template-columns: auto;
        grid-column-gap: 1.5rem;
    }

    .question-panel {
        overflow-y: scroll;
    }
}
