@import "@/assets/scss/main.scss";

.question-card {
    margin-bottom: 1rem;
    width: 100%;
}

section#question {
    display: flex;
    flex-direction: column;

    .question {
        display: grid;
        grid-template-columns: 20pt auto 32pt 24pt;
    }

}
