@import "@/assets/scss/main.scss";

.question-header-card {
    border: none;
    height: 5rem;
    border-bottom: 2px solid lightgrey;
    display: flex;

    section#question-header {
        display: flex;
        flex-direction: column;

        .question-header {
            display: grid;
            grid-auto-flow: row;
            grid-template-columns: 5% 2% 85% 2% 2%;
            grid-gap: 10px;
        }
    }

}
