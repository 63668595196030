@import "@/assets/scss/main.scss";


.PopUp {
    text-decoration: underline;
    color: #4BB3D4;
    cursor: pointer;
}

.question-header-card {
    border: none;
    height: 5rem;
    border-bottom: 2px solid lightgrey;
    display: flex;

    section#question-header {
        display: flex;
        flex-direction: column;

        .question-header {
            display: grid;
            grid-auto-flow: row;
            grid-template-columns: 5% 2% 85% 5%;
            grid-gap: 10px;
        }
    }

}

.button-footer {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 50% 50%;
}

.question-nav-button {
    display: flex;
    background-color: #ECF0F3;
    border: none;
    color: #485159;
    margin: 0 10px 0 10px;
    width: 210px;
    height: 47px;
    align-items: center;
    justify-content: center;
}

.right-button-profil {
    width: 240px;
}

.question-detail {
    margin-bottom: 1rem;
    width: 100%;
    border: none;
    height: 650px;
}

.hint-multicheckbox {
    color: $elsi-color-schrift-hellgrau;
}

section#question {
    display: flex;
    flex-direction: row;
    justify-content: center;

    // calc: 1rem margin from collapsible
    .question {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 10px;
        grid-template-rows: repeat(var(--row-count), 1fr);
        grid-auto-columns: minmax(0, 1fr);

    }
}
